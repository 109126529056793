import React from "react";

const WASH = () => {
  return (
    <>
      <div className="img">   
        <img src="WASH.jpeg"/>
      </div>
      <div className="wash">
        {/* <h3><u>Introduction</u></h3> */}
        During the financial year 2019-20, behavior change workshops were
        conducted in both government boys and girls in Alwar, Rajasthan along
        with construction of sanitation blocks and kitchen and installation of
        water RO and water cooler. After the COVID-19, an impact study of the
        same project was conducted by Janman in March 2022. The objective of
        this assessment was to examine students' existing sanitation behaviors
        in the two schools and analyze their change in behavior towards personal
        hygiene and community sanitation. The other objective was to assess the
        current state of the project's execution in terms of sanitation block
        and kitchen infrastructure and the predicted advantages to students and
        school administration in both schools.
      </div>
    </>
  );
};

export default WASH;
