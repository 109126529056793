import React from 'react'

const GetInvolved = () => {
  return (
    <div className='involved'>
      <h3>Get Involved with us</h3>
      <a href="https://forms.gle/k6YNi1nfSA4V8Rdg9" target='blank'>Click Here</a>
    </div>
  )
}

export default GetInvolved
