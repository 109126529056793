import React from "react";

const Covid = () => {
  return (
    <>
      <div className="covid">
        <h1>Covid-19 Relief</h1>
        <img src="Covid-2.jpg" width={"80%"} />
      </div>

      <div className="areaText">
        <h3 className="heading">COVID-19 relief services:</h3>
        <p>
          During the COVID-19 relief efforts, Janman People's Foundation focused
          on providing critical support in three key areas: distributing ration
          kits to address food insecurity, distributing hygiene kits to promote
          health and sanitation, and facilitating access to essential medical
          resources such as oxygen cylinders and hospital beds to aid those
          affected by the pandemic. In addition to our efforts in ration
          distribution, hygiene kit distribution, and facilitating access to
          medical resources, Janman People's Foundation also extended a helping
          hand to migrant workers facing immense hardships during the pandemic.
          We provided essential support to meet their immediate needs and
          assisted them in arranging safe travel back to their hometowns. A
          brief of these areas is given below:{" "}
        </p>
        <h4 className="subHeading">
          Distributing food and ration kits to address food insecurity
        </h4>
        <p>
          Janman, during the first and second waves of COVID-19 in India,
          initiated a comprehensive dry ration distribution drive. By forming an
          organic reach in 10 states and UTs of the country, the organization
          provided essential support to vulnerable communities, including
          seasonal migrant workers, daily wagers, sex workers, transgenders,
          unorganized sector workers, and other marginalized groups. The
          distribution drive aimed to alleviate the immediate hardships faced by
          these communities during the subsequent lockdowns, ensuring access to
          food and essential supplies. The dry ration distribution drive was
          instrumental in meeting the basic food requirements of marginalized
          communities, who faced severe challenges due to job losses and lack of
          income during the lockdowns. We also provided regular meals to
          homeless individuals and daily wage workers, helping alleviate hunger
          and ensuring access to nutritious food during the lockdown. The
          distribution of hygienic and nutritious meals contributed to the
          overall health and well-being of beneficiaries, boosting their
          immunity during the pandemic. The project specifically targeted
          homeless individuals and daily wagers, providing them with essential
          support during an economically challenging time. The drive exemplified
          a community-driven approach, promoting inclusivity, empathy, and
          solidarity while addressing immediate needs and inequalities caused by
          the pandemic. It not only ensured food security and enhanced
          well-being but also demonstrated the commitment of Janman towards an
          empowered and sustainable future for all. Overall, close to 10,000,00
          meals were distributed to 20,000 families in more than 10 states and
          UTs.
        </p>
        <h4 className="subHeading">
          Distribution of Hygiene Kits to promote health and hygiene
        </h4>
        <p>
          While women in India suffer in silence during menstruation, nationwide
          lockdown due to COVID-19 makes it more difficult for them. During the
          lockdown, Janman initiated a hygiene kit distribution initiative to
          support women from marginalized groups. The initiative aimed to
          address the specific Menstrual Hygiene needs of women engaged in the
          unorganized sector such as daily wage workers, house help, contractual
          safai karamcharis, etc. The hygiene kits included sanitary napkins,
          soap, face masks, and hand sanitizers, which were vital for
          maintaining personal hygiene and preventing the spread of COVID-19.
          The organization collaborated with local volunteers, NGOs working in
          the area to ensure that the targeted distribution is done in the
          community. With the help of India Post we managed to prepare and send
          the hygiene kits to other states as well. These hygiene kits
          facilitated women to maintain personal hygiene during challenging
          times, contributing to better health and well-being. Access to
          sanitary napkins helped women manage their menstrual hygiene with
          dignity, ensuring their continued participation in daily activities.
        </p>
        <h4 className="subHeading">Sewa Kits to Migrant workers walking</h4>
        <p>
          When the first Covid-19 pandemic-induced lockdown was announced across
          India in March 2020, millions of migrant workers were stranded without
          work or food and started walking back to their homes in different
          states. The streets of Delhi and other metropolitan cities saw an
          exodus of migrant workers having no means to pay for their food or
          rent. In Delhi-NCR, Janman created a Sewa kit for those migrants who
          were walking back to their homes in the scorching heat. The small and
          handy kit included water, juice, glucose biscuits and candies to keep
          them hydrated on their journey back home. The organization distributed
          more than 1,00,000 such kits to the migrants compelled to walk back to
          their homes.
        </p>
      </div>
    </>
  );
};

export default Covid;
