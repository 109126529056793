import React from "react";

const VisionMission = () => {
  return (
    <>
      <div className="vision">
        <h3 className="heading-Vision">Vision:</h3>
        <ul>
          <li>
            <u>Ajust and equitable society</u>
            <p>
              {" "}
              The foundation's vision is to create a society that is just and
              equitable for all. This means that every individual is treated
              with dignity, respect, and fairness, and has equal access to
              opportunities and resources
            </p>
          </li>
          <li>
            <u> Access to equal opportunities:</u>
            <p>
              We aim to promote and protect the fundamental human rights of
              every individual, including the right to life, liberty, and
              equality before the law. This means that we work towards creating
              a society that is free from discrimination, oppression, and
              violence.
            </p>
          </li>
          <li>
            <u> Sustainable Development:</u>
            <p>
              Janman People's Foundation envisions a society that is sustainable
              and eco-friendly, where natural resources are conserved and
              utilized in a responsible and equitable manner. We believe that
              sustainable development is crucial for creating a just and
              equitable society
            </p>
          </li>
        </ul>
        <h3 className="heading-Vision">Mission:</h3>
        <ul>
          <li>
           <u> Promoting and protecting human rights:</u>
            <p>
              {" "}
              The foundation's primary mission is to promote and protect human
              rights in India. We provide legal aid and support to individuals
              and communities who have suffered human rights violations, and we
              work towards creating a society that is free from discrimination
              and oppression. This includes issues such as climate justice,
              housing rights, Dalit rights, prisoner's rights, health rights,
              and LGBTQ+ rights.
            </p>
          </li>
          <li>
           <u> Empowering marginalized and vulnerable sections of society:</u>
            <p>
              {" "}
              We aim to empower marginalized and vulnerable sections of society,
              including women, children, Dalits, and the LGBTQ+ community. We
              strive to create opportunities for these communities to access
              education, healthcare, and other essential services. Through our
              various initiatives, we work towards ensuring that these
              communities are not left behind and have a voice in society
            </p>
          </li>
          <li>
           <u> Community-driven initiatives:</u>
            <p>
              {" "}
              Janman People's Foundation undertakes various community-driven
              initiatives that address the most pressing issues faced by the
              people of Bihar. These initiatives are designed to be sustainable
              and impactful, and they are implemented with the active
              participation of local communities. We believe that
              community-driven initiatives are crucial for creating long-term
              change in society
            </p>
          </li>
          <li>
           <u> Advocacy and awareness-raising:</u>
            <p>
              {" "}
              We engage in advocacy and awareness-raising activities to promote
              social justice and human rights. We work to raise awareness about
              issues such as climate change, gender equality, and the rights of
              marginalized communities. We also advocate for policy changes and
              reforms that promote social justice and human rights
            </p>
          </li>
          <li>
           <u> Building partnerships and collaborations:</u>
            <p>
              {" "}
              We believe that achieving our mission requires collaboration and
              partnerships with like-minded organizations and individuals. We
              work towards building partnerships with other NGOs, civil society
              organizations, and government agencies to create a more impactful
              and sustainable change. We believe that working together is
              crucial for achieving our vision of a just and equitable society
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default VisionMission;
