import Slideshow from "./Slideshow";
const JanAbhiyan = () => {
  return (
    <>
      <div>
        <Slideshow />
      </div>
      <div className="areaText">
        <h1>Jan Nyay Abhiyan</h1>
        <h5><u>Access to Justice</u></h5>
        <p>
          Access to legal justice is a fundamental right that every citizen has,
          regardless of their social or economic status. However, in India,
          marginalized communities and weaker sections of society often face
          significant barriers to accessing justice, leading to a lack of
          redressal for their grievances. According to a report by the National
          Legal Services Authority, over 80% of the prison population in India
          is comprised of underprivileged and marginalized communities.
          Moreover, according to the National Crime Records Bureau, crimes
          against Dalits and Adivasis increased by 7.3% and 26.5%, respectively,
          in 2019. As an organization working towards promoting social justice
          and equality, we focus on empowering marginalized communities and
          weaker sections of society to access justice and protect their rights.
        </p>
        <p>
          The Initiative’s activities are delivered through various means such
          as training, workshops, seminars, litigation, fact findings, and
          advocacy through campaigns. The project aims to empower marginalized
          communities in Bihar by providing legal aid, promoting social justice,
          and addressing systemic inequalities. Through a comprehensive approach
          encompassing legal advocacy, community engagement, capacity building,
          and stakeholder collaboration, we seek to create positive change and
          uplift the lives of marginalized individuals and groups. Our operating
          model involves collaborating with stakeholders which include, the
          target community itself, localised civil society organizations, legal
          professionals, government agencies, and individual social activists.
          By working closely with these stakeholders, we aim to address systemic
          inequalities and advocate for the rights of marginalized groups.
          Through our training programs, we educate communities and individuals
          about their rights and empower them to actively participate in
          decision-making processes - one of the major modes being through
          taking their cases to the courts of law. Additionally, organize
          workshops, seminars and legal aid camps to raise awareness on social
          justice issues and provide platforms for marginalized voices to be
          heard. To understand the issues faced by the community better, and do
          evidence-based litigation of violations faced by the community, we
          also conduct fact findings which involve activists and lawyers
          visiting the aggrieved families to enable redressal of their concerns.
          As a result we have filed various cases before different forums in
          Bihar including the High Court and Tribunals. The litigation by the
          Janman People's Foundation before the Patna High Court has resulted in
          significant policy level impacts. These include: A.Doorstep Delivery
          of Medicines for People Living with HIV B.Inclusion of Transgenders in
          Government Service Recruitments C.Special Focus on Thalassemia
          Patients D.Banning of Felling of Trees and Compulsory Translocation of
          Trees in Developmental Projects E.Better implementation of the
          Compensatory Afforestation Fund Management and Planning Authority Act
          in NHAI projects.
        </p>
      </div>
    </>
  );
};

export default JanAbhiyan;
