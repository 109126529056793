// Profiles.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CardComponent from './CardComponent';

const OurTeam = () => {
  const profileData = [
    {
      name: 'Shashwat',
      role: 'Co-Founder',
      image: 'shaswat.jpg',
      linkedin: 'https://www.linkedin.com/in/shashwat-srivastava-03499985?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
      name: 'Priyam Agarwal',
      role: 'Co-Founder',
      image: 'agarwal.jpg',
      linkedin: 'https://www.linkedin.com/in/priyam-agarwal?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
      name: 'Shourya Roy',
      role: 'Co-Founder',
      image: 'shourya.jpg',
      linkedin: 'https://www.linkedin.com/in/shourya-roy-8a71b633?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
      name: 'Rajeev Rai',
      role: 'Co-Founder',
      image: 'rajeevRai.jpg',
      linkedin: 'https://www.linkedin.com/in/rajeev-kumar-rai-?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
      name: 'Vivek Raj',
      role: 'Policy Analyst',
      image:'vivek.jpg',
      linkedin: 'https://www.linkedin.com/in/rajeev-kumar-rai-?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    },
    {
      name: 'Prakash Kumar',
      role: 'Lead Social Worker',
      // image: 
    },
    {
      name: 'Sachina',
      role: 'Lawyer',
      // image: 
    },
  ];

  return (
    <Container>
      <Row>
        <h1 className='fName'>Our Team</h1>
        {profileData.map((profile, index) => (
          <Col key={index} sm={12} md={6} lg={3}>
            <CardComponent
              name={profile.name}
              role={profile.role}
              image={profile.image}
              description={profile.description}
              instagram={profile.instagram}
              linkedin={profile.linkedin}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default OurTeam;
