import React from 'react';
import { Container, Row, Col, CloseButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div>
       <Container>
        <Row>
          <Col md={4} className="footer-section">
            <h5>Address</h5>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Delhi Office: B493
              vasant kunj enclave, Vasant kunj New Delhi - 110070
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} />Patna Office:
              L-8, L.S Apartment, Road no 39, Anisabad, Patna
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} />Purnea Office:
              Madhubani Road, Dollar House Chowk, Sipahi Tola Purnea, Bihar- 854301
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> +91-9953591267
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> janman.justice@gmail.com
            </p>
          </Col>
          <Col md={10} className="footer-section">
            <h5>Social Media</h5>
            <div className="social-media-icons" >
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className='icon' />
              </a>
              <a
                href="http://www.twitter.com/janman_org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} className='icon' />
              </a>
              <a
                href="http://www.instagram.com/janman_org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className='icon' />
              </a>
              <a
                href="https://www.linkedin.com/company/janmanorg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} className='icon'/>
              </a>
            </div>
          </Col>
          </Row>
        </Container>
    </div>
  );
}

export default Contact;
