//mock.js

export const mockData = [
    {
        src:'WASH.jpeg'
    },
    {
        src:'janAbhiyan3.jpg'
    },
    {
        src:'janAbhiyan2.jpg'
    },
    {
        src:'SEL.jpeg'
    },
    {
        src:"Covid.jpg"
    },
    {
        src:"Covid-2.jpg"
    },
    {
        src:"IMG-1.jpg"
    },
    {
        src:"IMG-2.jpg"
    },
    {
        src:"IMG-3.jpg"
    },
    {
        src:'saahil.jpg'
    },
    {
        src:'saahil1.jpg'
    },
    {
        src:'saahil2.jpg'
    },
    {
        src:'saahil3.jpg'
    },
];
