import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Home = () => {
  return (
    <Container fluid>
      <div className="homeImg mb-4">
        <img
          src="6.jpg"
          alt="home"
          className="img-fluid"
          style={{ width: "100%", height: "auto" }}
        />
        <div className="text-container">
          <p>“Injustice makes the rules, and courage breaks them.”</p>
        </div>
      </div>
      <Row className="homeContent my-4">
        <Col md={6} className="mb-3">
          <img src="janAbhiyan3.jpg" alt="Jan Abhiyan" className="img-fluid" />
        </Col>
        <Col md={6} className="mb-3">
          <div className="textInP">
            <h5>
              <b>Jan Nyay Abhiyan</b>
            </h5>
            <h6>Goals:</h6>
            <ul>
              <li>
                Empower marginalized communities by providing them with legal
                aid, support, and awareness of their rights.
              </li>
              <li>
                Promote social justice by advocating for policy reforms and
                addressing systemic inequalities.
              </li>
              <li>
                Enhance environmental sustainability through awareness campaigns
                and training programs on climate justice and sustainable living.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="homeContent1 my-4">
        <Col md={6} className="mb-3">
          <img
            src="boondimg2.jpg"
            alt="Boond"
            className="img-fluid"
          />
        </Col>
        <Col md={6} className="mb-3">
          <div className="textInP">
            <h5>
              <b>Project Boond</b>
            </h5>
            <p>
              On International Women’s Day in March 2021, Janman launched the
              project BOOND, Menstrual Health, and Hygiene Management (MHM)
              program. This project attempted to raise awareness of menstrual
              hygiene and broaden the accessibility of safe, hygienic menstrual
              products. The lack of Menstrual Hygiene has always been a
              challenge in India largely because for the longest time, the
              Indian society has treated menstruation as a taboo. Less than 20
              percent menstruating women in India have access to sanitary
              products.The project majorly works towards creating menstrual
              friendly spaces in slums of Delhi and in panchayats of Bihar. The
              project focus on involving non-menstruators as well which includes
              adolescent boys, brothers, fathers and sons in the family, and we
              educate them on menstruation to make them more sensitive around
              the issue
            </p>
          </div>
        </Col>
      </Row>

      <Row className="homeContent my-4">
        <Col md={6} className="mb-3">
          <img
            src="WASH.jpeg"
            alt="School Sanitation and WASH"
            className="img-fluid"
          />
        </Col>
        <Col md={6} className="mb-3">
          <div className="textInP">
            <h5>
              <b>
                Impact study of school sanitation and <b>WASH</b> project by KOHLER and
                Habitat for Humanity in Alwar, Rajasthan:
              </b>
            </h5>
            <p>
              During the financial year 2019-20, behavior change workshops were
              conducted in both government boys and girls schools in Alwar,
              Rajasthan along with construction of sanitation blocks and kitchen
              and installation of water RO and water cooler. After the COVID-19,
              an impact study of the same project was conducted by Janman in
              March 2022. The objective of this assessment was to examine
              students' existing sanitation behaviors in the two schools and
              analyze their change in behavior towards personal hygiene and
              community sanitation. The other objective was to assess the
              current state of the project's execution in terms of sanitation
              block and kitchen infrastructure and the predicted advantages to
              students and school administration in both schools.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
