import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="about-us">
        <h1>About Us</h1>
        <p>
          Janman People's Foundation is a youth-based organization registered
          under section 8, of the Companies Act. It is led by development sector
          professionals committed to bringing positive change in society. The
          organisation focuses on working at the grass-root level to address
          various social issues through innovative programs and initiatives in
          Behavioral Change, Civic Engagement, Access to Justice, Community
          Empowerment, and Disaster Relief. Through interactive Behavior Change
          Communication (BCC) processes, the organisation aims to promote
          healthy and positive practices on WASH, menstrual health, and gender
          justice. In India, marginalized communities and weaker sections of
          society often face significant barriers to accessing justice. One key
          challenge that they usually face is the absence and lack of effective
          grievance redressal mechanisms. Through our targeted programs, we
          empower these communities to protect their rights and access justice.
          Our Community Empowerment initiatives aim to create self-sustaining,
          resilient, and equitable environments that can drive sustainable
          development. We at Janman recognize that communities are best placed
          to identify and address their unique challenges. We advocate for
          policies and practices to promote inclusive and sustainable
          development, focusing on marginalized and vulnerable communities. We
          collaborate with local communities, government bodies, and other
          organizations to provide relief services during disasters, such as
          annual floods in Bihar, and alleviate hardships faced by people. With
          our community-driven approach, we have been working tirelessly at the
          grass-root level to bring about positive change and provide support to
          the last person standing in the queue. We hope to continue to strive
          towards creating a better future for all.{" "}
        </p>
        <br/>
      </div>
    </>
  );
};

export default AboutUs;
