import React from "react";

const SEL = () => {
  return (
    <>
    <div className="sel">
        <h3>SEL Initiative</h3>
      <img src="SEL.jpeg" width={"80%"} alt="SEL Photo" />
      </div>
      <div className="sel-content">
      <p>
        Adolescence is a critical developmental stage, and individuals living in
        underprivileged environments face unique challenges that can hinder
        their overall growth and potential. Social and Emotional Learning (SEL)
        empowers individuals to navigate social challenges, build resilience,
        and thrive in various aspects of life. Janman People’s Foundation and
        Gyan Deesha Foundation, did a three months long intervention with
        adolescents on Social Emotional Learning with an aim to empower these
        adolescents with essential life skills, enhance their emotional
        intelligence, and promote their overall mental well-being. The workshops
        were conducted in Barola JJ slum, in sector 49, Noida, Gautam Buddha
        District, Uttar Pradesh. SEL workshops provide a structured platform to
        develop essential life skills, such as self-awareness, self-management,
        empathy, and responsible decision-making. By equipping adolescents with
        these skills, our workshops were aimed at promoting positive mental
        health, enhancing resilience, improving interpersonal relationships, and
        fostering a supportive and inclusive environment.
      </p>
    </div>
    </>
  );
};

export default SEL;
