import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row, Image } from "react-bootstrap";
import { mockData } from "./mock";

function Gallery() {
  const handleClick = () => {};
  return (
    <Container>
      <Row>
        {mockData.map((data, index) => (
          <Col md={4} key={index}>
            <div className="img-card" onClick={() => handleClick(data.src)}>
              <Image
                style={{ width: "300px", height: "300px" }}
                thumbnail
                src={data.src}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
export default Gallery;
