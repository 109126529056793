import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./NavbarComponent.css"; // Import custom CSS for additional styling

const NavbarComponent = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="mb-3">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand className="logoName">
            <img
              className="logo"
              src="logo.jpg"
              width="50"
              style={{ borderRadius: 6 }}
              alt="logo"
              height="50"
            />
            Janman People’s Foundation
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto gap-3">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <NavDropdown
              title="About Us"
              id="collasible-nav-dropdown"
              className="hoverable-dropdown"
            >
              <LinkContainer to="/about-us">
                <NavDropdown.Item>About Us</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/vision-mission">
                <NavDropdown.Item>Vision and Mission</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/our-team">
                <NavDropdown.Item>Our Team</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown
              title="Our Initiatives"
              id="collasible-nav-dropdown"
              className="hoverable-dropdown"
            >
              <LinkContainer to="/janabhiyan">
                <NavDropdown.Item>Jan Nyay Abhiyan</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/boond">
                <NavDropdown.Item>Project Boond</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/covid">
                <NavDropdown.Item>Covid-19 Relief</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/sel">
                <NavDropdown.Item>SEL</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/wash">
                <NavDropdown.Item>WASH Initiative</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/saahil">
                <NavDropdown.Item>Project Saahil</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/gallery">
              <Nav.Link>Gallery</Nav.Link>
            </LinkContainer>
            <LinkContainer to="get-involved">
              <Nav.Link>Get Involved</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact-us">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
