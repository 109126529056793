import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4} className="footer-section">
            <h5>Address</h5>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Delhi Office: B493
              vasant kunj enclave, Vasant kunj New Delhi - 110070
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} />Patna Office:
              L-8, L.S Apartment, Road no 39, Anisabad, Patna
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} />Purnea Office:
              Madhubani Road, Dollar House Chowk, Sipahi Tola Purnea, Bihar- 854301
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> +91-9953591267
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> janman.justice@gmail.com
            </p>
          </Col>
          <Col md={4} className="footer-section">
            <h5>Social Media</h5>
            <div className="social-media-icons">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="http://www.twitter.com/janman_org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href="http://www.instagram.com/janman_org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.linkedin.com/company/janmanorg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </Col>
          <Col md={4} className="footer-section">
            <h5>About Us</h5>
            <p>
              We are a non-profit organization dedicated to making the world a
              better place. Our mission is to provide support and resources to
              those in need.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">
            &copy; {new Date().getFullYear()} Janman. All rights reserved.
            Developed By{" "}
            <a href="https://www.linkedin.com/in/aayushj19/">Aayush </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
