  // ProfileCard.js
  import React from 'react';
  import { Card, Button } from 'react-bootstrap';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
  import './Cardcomponent.css';

  const CardComponent = ({ name, role, image, linkedin }) => {
    return (
      <Card className="profile-card">
        <Card.Img variant="top" src={image} className="profile-card-img" />
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{role}</Card.Subtitle>
          <div className="social-icons">
            <a href={linkedin} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
            </a>
          </div>
        </Card.Body>
      </Card>
    );
  };

  export default CardComponent;
