import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


const fadeImages = [
  {
  url:'boondimg.jpg',
  },
  {
  url:'boondimg2.jpg'
}];

const Slidesgal = () => {
  return (
    <div className="slide-container">
    <Fade>
      {fadeImages.map((fadeImage, index) => (
        <div key={index}>
          <img style={{ width: '100%',height:'100%' }} src={fadeImage.url} />
          <h2>{fadeImage.caption}</h2>
        </div>
      ))}
    </Fade>
  </div>
  );
};

const slideImages = [

  
  // ... more images
];

export default Slidesgal; 