import React from "react";
// import Slidesgal from "./Slidesgal";


// const fadeImages = [
//     {
//     url:'saahil.jpg',
//     },
//     {
//     url:'saahil1.jpg',
//     },
//     {
//     url:'saahil2.jpg'
//     },
//     {
//     url:'saahil3.jpg'
//     }
//     ];

const Saahil = () => {
  return (
    <>
    {/* <Slidesgal/> */}
    <div>
      <h3>
        Project Sahil (Distribution of Ration and Medical Supplies in
        Flood-Affected Areas of Bihar and Assam)
      </h3>
      <p>
        Project Sahil is an emergency relief initiative by Janman People's
        Foundation that aims to provide immediate assistance to communities
        affected by floods in Bihar and Assam. The foundation distributes
        essential items such as food, water, and medical supplies to affected
        communities, along with providing shelter and rehabilitation support.
        Project Sahil also works to mitigate the impact of floods by providing
        long-term solutions, such as building flood-resistant housing and
        supporting sustainable agriculture practices.
      </p>
    </div>
    </>
  );
};

export default Saahil;
