import React from "react";
// import Slideshow from "./Slideshow";
import Slidesgal from "./Slidesgal";

const Boond = () => {
  return (
    <>
      <h1>
        <u>Project Boond</u>
      </h1>
      <Slidesgal />
      <div className="boond">
        <h3><u>Introduction</u></h3>
        On International Women’s Day in March 2021, Janman launched the project
        BOOND, Menstrual Health, and Hygiene Management (MHM) program. This
        project attempted to raise awareness of menstrual hygiene and broaden
        the accessibility of safe, hygienic menstrual products. The lack of
        Menstrual Hygiene has always been a challenge in India largely because
        for the longest time, the Indian society has treated menstruation as a
        taboo. Less than 20 percent menstruating women in India have access to
        sanitary products. The team realized that there is a general arrogance
        around the menstrual need of women, as per the NFHS data only 36%
        menstruating women in India have access to the hygienic products during
        their periods. The project majorly works towards creating menstrual
        friendly spaces in slums of Delhi and in panchayats of Bihar. The
        project focus on involving non-menstruators as well which includes
        adolescent boys, brothers, fathers and sons in the family, and we
        educate them on menstruation to make them more sensitive around the
        issue. We sensitize whole community so that women and girls feel
        comfortable and confident during their periods. We distribute 100%
        biodegradable sanitary napkins or cloth napkins keeping in mind the
        environmental implication of plastic sanitary pads.<br></br>
        <p>
          The team realized that there is a general arrogance around the
          menstrual need of women, as per the NFHS data only 36% menstruating
          women in India have access to the hygienic products during their
          periods. The project majorly works towards creating menstrual friendly
          spaces in slums of Delhi and in panchayats of Bihar. The project focus
          on involving non-menstruators as well which includes adolescent boys,
          brothers, fathers and sons in the family, and we educate them on
          menstruation to make them more sensitive around the issue. We
          sensitize whole community so that women and girls feel comfortable and
          confident during their periods. We distribute 100% biodegradable
          sanitary napkins or cloth napkins keeping in mind the environmental
          implication of plastic sanitary pads.{" "}
        </p>
      </div>
    </>
  );
};

export default Boond;
