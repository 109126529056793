import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavbarComponent from './components/NavbarComponent';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import GetInvolved from './components/GetInvolved';
import Contact from './components/ContactUs';
import Footer from './components/Footer';
import Gallery from './components/Gallery'
import OurTeam from './components/OurTeam';
import VisionMission from './components/VisionMission';
import JanAbhiyan from './components/JanAbhiyan';
import Boond from './components/Boond';
import WASH from './components/WASH';
import Covid from './components/Covid';
import Saahil from './components/Saahil';
import SEL from './components/SEL';
const App = () => {
  return (
    <>
    <header>
    <Router>
      <NavbarComponent />
      <div className="container mt-5">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/get-involved" element={<GetInvolved />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/vision-mission" element={<VisionMission />} />
          <Route path="/janabhiyan" element={<JanAbhiyan />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/boond" element={<Boond />} />
          <Route path="/wash" element={<WASH />} />
          <Route path="/covid" element={<Covid />} />
          <Route path="/saahil" element={<Saahil />} />
          <Route path="/sel" element={<SEL />} />
        </Routes>
      </div>
    </Router>
    </header>
    <Footer/>
    </>
  );
};

export default App;
